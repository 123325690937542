import LayoutContainer from 'components/Common/LayoutContainer/LayoutContainer'
import Pane from 'components/Common/Pane'
import TextButton from 'components/Luxkit/Button/TextButton'
import Group from 'components/utils/Group'
import TabButton from 'components/Luxkit/Tabs/TabButton'
import TabButtonsGroup from 'components/Luxkit/Tabs/TabButtonsGroup'
import Heading from 'components/Luxkit/Typography/Heading'
import { useInView } from 'react-intersection-observer'
import Subtitle from 'components/Luxkit/Typography/Subtitle'
import { rem } from 'polished'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import BusinessTravellerBusinessesBenefitsSection from './BusinessTravellerBusinessesBenefitsSection'
import BusinessTravellerEmployeesBenefitsSection from './BusinessTravellerEmployeesBenefitsSection'
import SubtitleTextBlock from 'components/Luxkit/TextBlocks/SubtitleTextBlock'
import LineCheckIcon from 'components/Luxkit/Icons/line/LineCheckIcon'
import { mediaQueryUp } from 'components/utils/breakpoint'
import zIndex from 'styles/tools/z-index'
import LineBuildingIcon from 'components/Luxkit/Icons/line/LineBuildingIcon'
import LineUsersAltIcon from 'components/Luxkit/Icons/line/LineUsersAltIcon'
import debounce from 'debounce-promise'
import ModalContext from 'contexts/ModalContext'
import BusinessTravellerRequestDemoModal from 'businessTraveller/components/demo-modal/BusinessTravellerRequestDemoModal'

const heroVideoUrl = 'https://player.vimeo.com/progressive_redirect/playback/897048748/rendition/720p/file.mp4?loc=external&signature=b0eb606a632ccc13e18c72d64408670a7fa3aa666fa6954cf2629943031a3916'

const ContentPane = styled(Pane)`
  position: relative;
`

const TopHeroContainer = styled(LayoutContainer)`
  display: flex;
  align-items: center;

  ${mediaQueryUp.tablet} {
    height: ${rem(550)};
  }
`

const StickyTabsWrapper = styled.div`
  position: sticky;
  top: 0;
  margin-left: ${rem(-12)};
  margin-right: ${rem(-12)};
  z-index: ${zIndex.stickyHeader};

  ${mediaQueryUp.tablet} {
    position: absolute;
    margin: 0;
    padding: 0 ${rem(12)};
    display: flex;
    justify-content: center;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    transition: transform 0.3s, top 0.3s;

    &.full-size {
      position: fixed;
      top: 0;
      transform: translateY(0) translateX(-50%);
    }
  }
`

const StickyTabsSheet = styled(Pane)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  ${mediaQueryUp.tablet} {
    box-shadow: ${props => props.theme.shadow.bottom.medium};
    border-radius: 2px;
    transform: scaleX(1);
    transition: transform 0.3s;

    &.full-size {
      border-radius: 0;
      transition: transform 0.3s;
      transform: scaleX(var(--menu-scale, 1));
    }
  }
`

const BusinessTravellerImageBanner = styled.div`
  position: relative;
`

const HeroGroupContent = styled(Group)`
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  max-width: 100%;

  ${mediaQueryUp.tablet} {
    max-width: ${rem(500)};
  }
`

const BusinessTravellerHeroVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
`

const VideoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #000 10.2%, rgba(0, 0, 0, 0.00) 58.33%);
  }
`

function BusinessTravellerSalesLandingPage() {
  const [selectedTab, setSelectedTab] = useState<'businesses' | 'employees'>('businesses')
  const [scale, setScale] = useState<number>()

  const selectBusinessesTab = useCallback(() => {
    setSelectedTab('businesses')
  }, [])

  const selectEmployeesTab = useCallback(() => {
    setSelectedTab('employees')
  }, [])

  const menuRef = useRef<HTMLDivElement>(null)
  const [tabContentRef, isTabContentInView] = useInView()
  const [menuAnchorRef, isMenuAnchorInView] = useInView()

  useEffect(() => {
    const calcScale = () => setScale(window.innerWidth / (menuRef.current?.offsetWidth ?? 1))
    const debouncedCalc = debounce(calcScale, 50)
    window.addEventListener('resize', debouncedCalc)
    calcScale()

    return () => window.removeEventListener('resize', debouncedCalc)
  }, [])

  const showFullSizeBar = isTabContentInView && !isMenuAnchorInView

  const showModal = useContext(ModalContext)
  const toggleModal = useCallback(() => {
    showModal(<BusinessTravellerRequestDemoModal/>)
  }, [showModal])

  return <>
    <Pane paletteType="inverse">
      <BusinessTravellerImageBanner>
        <BusinessTravellerHeroVideo
          controls={false}
          autoPlay
          playsInline
          loop
          muted
        >
          <source
            src={heroVideoUrl}
            type="video/mp4"
          />
        </BusinessTravellerHeroVideo>
        <VideoOverlay />
        <TopHeroContainer>
          <HeroGroupContent
            direction="vertical"
            gap={40}
            tabletDirection="horizontal"
            tabletGap={72}
            tabletHorizontalAlign="space-between"
            tabletVerticalAlign="center"
          >

            <Group direction="vertical" gap={32} tabletHorizontalAlign="start">
              <Heading variant="heading1">Business Travel <i>made easy</i></Heading>

              <Group direction="vertical" gap={12}>
                <Subtitle variant="subtitle2">
                  Set up and manage your staff travel in minutes.
                </Subtitle>

                <Group direction="vertical" gap={4}>
                  <SubtitleTextBlock variant="subtitle2" startIcon={<LineCheckIcon />}>
                    Create travel policies that match your company's needs
                  </SubtitleTextBlock>
                  <SubtitleTextBlock variant="subtitle2" startIcon={<LineCheckIcon />}>
                    Access realtime reporting on your business travel expenditures
                  </SubtitleTextBlock>
                  <SubtitleTextBlock variant="subtitle2" startIcon={<LineCheckIcon />}>
                    Incentivise your employees to spend below budget
                  </SubtitleTextBlock>
                </Group>
              </Group>

              <TextButton kind="secondary" onClick={toggleModal} size="large">
                Request a demo
              </TextButton>
            </Group>
          </HeroGroupContent>
        </TopHeroContainer>
      </BusinessTravellerImageBanner>
    </Pane>

    <ContentPane>
      <div aria-hidden="true" ref={menuAnchorRef}/>
      <StickyTabsWrapper className={showFullSizeBar ? 'full-size' : undefined}>
        <StickyTabsSheet style={{ '--menu-scale': scale }} ref={menuRef} className={showFullSizeBar ? 'full-size' : undefined} />
        <TabButtonsGroup fit="grow">
          <TabButton startIcon={<LineBuildingIcon/>} size="large" isActive={selectedTab === 'businesses'} onClick={selectBusinessesTab}>
            For Businesses
          </TabButton>
          <TabButton startIcon={<LineUsersAltIcon/>} size="large" isActive={selectedTab === 'employees'} onClick={selectEmployeesTab}>
            For Employees
          </TabButton>
        </TabButtonsGroup>
      </StickyTabsWrapper>
      <div ref={tabContentRef}>
        {selectedTab === 'businesses' && <BusinessTravellerBusinessesBenefitsSection />}
        {selectedTab === 'employees' && <BusinessTravellerEmployeesBenefitsSection />}
      </div>
    </ContentPane>
  </>
}

export default BusinessTravellerSalesLandingPage
