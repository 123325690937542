import LayoutContainer from 'components/Common/LayoutContainer'
import Group from 'components/utils/Group'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Heading from 'components/Luxkit/Typography/Heading'
import { rem } from 'polished'
import React, { ComponentProps, useCallback, useContext } from 'react'
import styled from 'styled-components'
import BusinessTravellerAdvantagesGroup from './sections/BusinessTravellerAdvantagesGroup'
import BusinessTravellerAdvantageTile from './sections/BusinessTravellerAdvantageTile'
import LineCoinsIcon from 'components/Luxkit/Icons/line/LineCoinsIcon'
import LineAwardIcon from 'components/Luxkit/Icons/line/LineAwardIcon'
import LineGraphBarIcon from 'components/Luxkit/Icons/line/LineGraphBarIcon'
import Pane from 'components/Common/Pane/Pane'
import { mediaQueryUp } from 'components/utils/breakpoint'
import HorizontalContainerWithFadedOverflow from 'components/Common/HorizontalContainerWithFadedOverflow'
import BusinessTravellerSolutionTile from './sections/BusinessTravellerSolutionTile'
import TextButton from 'components/Luxkit/Button/TextButton'
import LineShieldIcon from 'components/Luxkit/Icons/line/LineShieldIcon'
import LineCommentsAltIcon from 'components/Luxkit/Icons/line/LineCommentsAltIcon'
import LineUserIcon from 'components/Luxkit/Icons/line/LineUserIcon'
import LineShopIcon from 'components/Luxkit/Icons/line/LineShopIcon'
import LineBuildingIcon from 'components/Luxkit/Icons/line/LineBuildingIcon'
import BusinessTravellerHeroSection, { BusinessTravellerHeroSectionContentContainer } from './sections/BusinessTravellerHeroSection'
import BusinessTravellerRequestDemoModal from 'businessTraveller/components/demo-modal/BusinessTravellerRequestDemoModal'
import ModalContext from 'contexts/ModalContext'
import { getImageUrl } from 'lib/image/imageUtils'
import TextLink from 'components/Luxkit/TextLink'

const promoVideoUrl = 'https://player.vimeo.com/progressive_redirect/playback/900963663/rendition/1080p/file.mp4?loc=external&signature=10cdc8ab8104e3fdef25c435970691ce3de96a6b26fad50cbe619416e38c66e3'

const BUSINESS_ADVANTAGES: Array<ComponentProps<typeof BusinessTravellerAdvantageTile>> = [
  {
    IconComponent: LineCoinsIcon,
    title: 'Incentivise Employees',
    description: 'Motivate your staff to spend below their travel budget.',
  },
  {
    IconComponent: LineAwardIcon,
    title: 'Exclusive Rates',
    description: 'Unlock exclusive discounts and get free inclusions like breakfast and valet parking.',
  },
  {
    IconComponent: LineGraphBarIcon,
    title: 'Reporting & Insights',
    description: 'Manage travel expenditures with a seamless booking and reporting process.',
  },
  {
    IconComponent: LineShieldIcon,
    title: 'Customised Policies',
    description: 'Tailor policies to your company’s unique needs, including spending limits and travel preferences.',
  },
  {
    IconComponent: LineCommentsAltIcon,
    title: '24/7 Support',
    description: 'Round-the-clock support from our dedicated team.',
  },
]

const BUSINESS_SOLUTIONS: Array<ComponentProps<typeof BusinessTravellerSolutionTile>> = [
  {
    IconComponent: LineUserIcon,
    title: 'Sole Trader',
    bulletPoints: [
      'Join for free and start booking your next business trip in under five minutes.',
      'Access competitive rates and discover hundreds of exclusive offers.',
    ],
  },
  {
    IconComponent: LineShopIcon,
    title: 'Small & Medium Businesses',
    bulletPoints: [
      'Join for free and start booking your next business trip in under five minutes.',
      'Gain valuable insights into travel expenditure, policy compliance, and travel patterns.',
      'Establish customised travel policies and reward your employees when they book under your travel budget.',
    ],
  },
  {
    IconComponent: LineBuildingIcon,
    title: 'Enterprise',
    bulletPoints: [
      'Join for free and start booking your next business trip in under five minutes.',
      'Discover solutions designed to grow with the demands of your business.',
      'Establish customised travel policies and reward your employees when they book under your travel budget.',
      'Solutions designed to scale with your growing business needs.',
    ],
  },
]

const AdvantagesContainer = styled(LayoutContainer)`
  padding-top: ${rem(40)};
  padding-bottom: ${rem(40)};

  ${mediaQueryUp.tablet} {
    padding-top: ${rem(80)};
    padding-bottom: ${rem(80)};
  }
`

const AdvantagesHeadingGroup = styled(Group)`
  max-width: ${rem(560)};
`

const SolutionsGroup = styled(Group)`
  > * {
    width: ${rem(260)};

    ${mediaQueryUp.tablet} {
      width: unset;
      flex: 1;
    }
  }
`

const BusinessTravellerPromoVideo = styled.video`
  width: 100%;
  object-fit: cover;

  ${mediaQueryUp.tablet} {
    width: ${rem(440)};
  }
`

function BusinessTravellerBusinessesBenefitsSection() {
  const showModal = useContext(ModalContext)
  const toggleModal = useCallback(() => {
    showModal(<BusinessTravellerRequestDemoModal/>)
  }, [showModal])

  return <section>
    <div>
      <AdvantagesContainer>
        <Group direction="vertical" gap={32}>
          <Group direction="horizontal" tabletHorizontalAlign="space-between" wrap="wrap" gap={24}>
            <AdvantagesHeadingGroup direction="vertical" gap={20}>
              <Heading variant="heading1">How we save you money and reward your staff</Heading>

              <BodyText variant="large">
                Elevate your business travel experience with our all-inclusive platform. Discover how we can save you money while enhancing the travel experience for your staff.
              </BodyText>
            </AdvantagesHeadingGroup>
            <BusinessTravellerPromoVideo
              controls
              poster={getImageUrl(
                'lebt-promo-thumbnail',
              )}
            >
              <source
                src={promoVideoUrl}
                type="video/mp4"
              />
            </BusinessTravellerPromoVideo>
          </Group>

          <BusinessTravellerAdvantagesGroup advantages={BUSINESS_ADVANTAGES} />
        </Group>
      </AdvantagesContainer>

      <BusinessTravellerHeroSection
        isInverted
        desktopImageId="bt-sales-business-credit-@desktop"
        mobileImageId="bt-sales-business-credit-@mobile"
      >
        <Group direction="vertical" gap={16}>
          <Group direction="vertical" gap={8}>
            <Heading variant="heading2">Incentivise Budget-Conscious Travel Choices</Heading>
            <BodyText variant="large" colour="neutral-one">
              A win-win for your company and your employees. Our platform encourages your staff to spend less than your travel budget by rewarding cost-effective decisions with credit for personal use on Luxury Escapes. Your staff are motivated to make budget-conscious choices, resulting in cost savings for your company and greater staff engagement.
            </BodyText>
          </Group>
        </Group>
      </BusinessTravellerHeroSection>

      <BusinessTravellerHeroSection
        isReversed
        desktopImageId="bt-sales-business-reporting-@desktop"
        mobileImageId="bt-sales-business-reporting-@mobile"
      >
        <Group direction="vertical" gap={8}>
          <Heading variant="heading2">Insights at Your Fingertips</Heading>
          <BodyText variant="large">
            Access our user-friendly dashboard to easily monitor bookings and review policy compliance. Generate comprehensive reports with detailed insights into your company’s travel expenditures.
          </BodyText>
        </Group>
      </BusinessTravellerHeroSection>

      <BusinessTravellerHeroSection
        isInverted
        desktopImageId="bt-sales-business-generic-@desktop"
        mobileImageId="bt-sales-business-generic-@mobile"
      >
        <Group direction="vertical" gap={8}>
          <Heading variant="heading2">Travel Policies, Made Easy</Heading>
          <BodyText variant="large" colour="neutral-one">
            Set spending limits, define preferred accommodation partners and select favoured airline carriers. Our easy-to-use policy management ensures your business travel program perfectly suits your organisation's needs.
          </BodyText>
        </Group>
      </BusinessTravellerHeroSection>

      <BusinessTravellerHeroSection
        isReversed
        desktopImageId="bt-sales-business-referral-@desktop"
        mobileImageId="bt-sales-business-referral-@mobile"
      >
        <Group direction="vertical" gap={16}>
          <Group direction="vertical" gap={8}>
            <Heading variant="heading2">Refer & earn $2,000</Heading>
            <BodyText variant="large">
              Refer a qualified business and receive <BodyText as="span" variant="large" weight="bold">$2,000</BodyText> in Luxury Escapes credit! Simply have your referee enter your email address in the referral field upon sign-up.
            </BodyText>
          </Group>
          <TextLink
            href="/terms-and-conditions#referral-program-terms-and-conditions"
            target="_blank"
            size="large"
            weight="bold"
          >
            Find out more
          </TextLink>
        </Group>
      </BusinessTravellerHeroSection>
    </div>

    <Pane type="light-grey">
      <BusinessTravellerHeroSectionContentContainer>
        <Group direction="vertical" gap={40}>
          <Group direction="vertical" gap={32}>
            <Heading variant="heading2">A solution to suit your needs</Heading>

            <HorizontalContainerWithFadedOverflow>
              <SolutionsGroup direction="horizontal" gap={24}>
                {BUSINESS_SOLUTIONS.map((solution) => <BusinessTravellerSolutionTile
                  key={solution.title}
                  IconComponent={solution.IconComponent}
                  title={solution.title}
                  bulletPoints={solution.bulletPoints}
                />)}
              </SolutionsGroup>
            </HorizontalContainerWithFadedOverflow>
          </Group>

          <Group
            direction="vertical"
            gap={24}
            tabletDirection="horizontal"
            tabletHorizontalAlign="space-between"
            tabletVerticalAlign="center"
          >
            <Heading variant="heading2">
              Get started today
            </Heading>

            <TextButton kind="secondary" onClick={toggleModal} size="large">
              Request a demo
            </TextButton>
          </Group>
        </Group>
      </BusinessTravellerHeroSectionContentContainer>
    </Pane>
  </section>
}

export default BusinessTravellerBusinessesBenefitsSection
